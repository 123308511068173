
  
.knowmore{
    width:180px;
    padding:10px;
    border:1px solid #ff9800;
    background: #ff9800;
    border-radius:50px;
    color:black;
    margin-top:5px;
    text-align: center;
    cursor: pointer;
    font-family: Poppins, Roboto;
    font-size: larger;
    font-weight: bold;
    outline:none;
    opacity: 1;

    animation-name:  flicker;
    animation-delay:  4s;
    animation-fill-mode: backwards;
    animation-duration:  4s;
}

.knowmore:hover{
    background: #00acc1;
    border:1px solid #00acc1;
    color: black;
}
  